<template>
    <article class="services__content">
        <h2>Shuttledienst</h2>
        <p>V-Tax Lybaert verzorgt tal van shuttlediensten voor evenementen, parkings, hotels, bedrijven...</p>
        <h3>Prijzen</h3>
        <p>Voor onze shuttlediensten kan u rekenen op een prijs van ongeveer <b>€60</b> per uur inclusief alle (kilometer)kosten.</p>
        <p>Neem contact op via <a href="tel:092222222">09/222.22.22</a> of <a href="mailto:info@v-tax.be">info@v-tax.be</a> voor uw prijs op maat.</p>
    </article>
</template>

<script>
    export default {
        name: "Shuttle"
    }
</script>