<template>
    <main class="page page--services services">
        <section class="page__content">
            <h1>Onze diensten</h1>
            <div class="services__options">
                <button v-for="item in services" @click="currentService=item.value"
                        class="button services__options__option"
                        :key="`service--${item.value}`"
                        :class="{'services__options__option--active':currentService===item.value}">{{item.label}}
                </button>

            </div>
            <Taxi v-if="currentService==='taxi'"></Taxi>
            <Minibus v-else-if="currentService==='mb'"></Minibus>
            <Limo v-else-if="currentService==='limo'"></Limo>
            <Shuttle v-else-if="currentService==='shuttle'"></Shuttle>
            <Vip v-else-if="currentService==='vip'"></Vip>
            <Distance v-else-if="currentService==='dist'"></Distance>
        </section>
    </main>
</template>

<script>
    import Taxi from "../components/services/Taxi";
    import Minibus from "../components/services/Minibus";
    import Limo from "../components/services/Limo";
    import Shuttle from "../components/services/Shuttle";
    import Vip from "../components/services/Vip";
    import Distance from "../components/services/Distance";
    export default {
        name: "Services",

        components:{
            Taxi,
            Minibus,
            Limo,
            Shuttle,
            Vip,
            Distance
        },

        data() {
            return {
                services: [
                    {
                        value: 'taxi',
                        label: 'Taxi'
                    },
                    {
                        value: 'mb',
                        label: 'Minibus'
                    },
                    {
                        value: 'limo',
                        label: 'Limo-service'
                    },
                    {
                        value: 'shuttle',
                        label: 'Shuttledienst'
                    },
                    {
                        value: 'vip',
                        label: 'Vervoer op maat'
                    },
                    {
                        value: 'dist',
                        label: 'Afstandsritten'
                    }
                ],
                currentService: this.$route.query.service || 'taxi',
            };
        }
    }
</script>